import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import AccuracyTestPDF from "./AccuracyTablePdf";
import FlowTestPDF from "./FlowTablePdf";
import MinMeasuredQtyPdf from "./MinMeasuredPdf";

function DownloadPdf() {
  const { id1,id2 } = useParams();
  useEffect(() => {
    async function downloadPdf(id1,id2) {
      const res=await axios({
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL+'/verify-pdf',
        params:{
            id1,
            id2
            }
      });
      const data=res.data[0];
      if(res.status===200){
        if(id1==="accuracytests"){
          AccuracyTestPDF("Accuracy Test Report",data)
        }
        else if(id1==="flowintruptiontests"){
          FlowTestPDF("Flow Intruption Test Report",data)
        } else if(id1==="minmeasuredqtytests"){
          MinMeasuredQtyPdf("Min Measured Quantity Test Report",data)
        }
      } 
    }
    downloadPdf(id1,id2);
    
  }, []);

  return (
    <div>
      <h2>Verify Pdf</h2>
      {/* You can include your PDF download logic here */}
    </div>
  );
}

export default DownloadPdf;
