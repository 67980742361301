import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Grid,} from '@mui/material';
import MinMeasuredQtyTable from '../Components/MinMeasuredQtyTable';
import { useLocation } from 'react-router-dom';
import ProjectDetail from '../Components/ProjectDetail';

export default function MinMeasuredQty() {
  const location = useLocation();
  const applicationData = location.state;

  const [testData, setTestData] = useState({
    initialTemp: 25,
    finalTemp: 27,
    beta: 0.000033,
    mmq: 2,
    maxRate: 130,
    minRate: 4,
    unitprice: 64.5,
    betatemp: 0,
    emin: 0,
    mspd: 0
  });

  useEffect(() => {
    setTestData(prevData => ({
      ...prevData,
      betatemp: (1 + prevData.beta * (prevData.finalTemp - prevData.initialTemp)),
      emin: (2 * prevData.mmq * 0.5) / 100,
      mspd: prevData.unitprice * (2 * prevData.mmq * 0.5) / 100
    }));
  }, []);

  const handleInputChange = (field, value) => {
    setTestData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handlePrintReport = () => {
  };

  return (
    <Box sx={{ textAlign: 'center', p: 3, backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
      <Typography variant="h5" gutterBottom>
        Minimum Measured Quantity Test
      </Typography>
      <ProjectDetail applicationData={applicationData} />
      <Grid container spacing={2} justifyContent="center">
        {Object.entries(testData).map(([field, value]) => (
          <Grid item xs={12} sm={6} md={2.3} key={field}>
            <TextField
              label={field}
              size="small"
              type="number"
              value={value}
              onChange={e => handleInputChange(field, e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            />
          </Grid>
        ))}
      </Grid>
      <MinMeasuredQtyTable testData={testData} applicationData={applicationData} handlePrintReport={handlePrintReport} />
    </Box>
  );
}
