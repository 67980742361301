import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ProjectDetail from "../Components/ProjectDetail";
import TestTable1 from "../Components/TestTable1";

function DetailedTestResult(){
    const [testData,setTestData]=useState(null);
    const location=useLocation();
    const project=location.state;
    useEffect(()=>{
        async function fetchData() {
            await axios({
                method:"GET",
                url:process.env.REACT_APP_BACKEND_URL+"/project-details",
                params: {
                    _id: project._id
                  }
            }).then(res=>{
                if(res.status === 200){
                    console.log(res.data);
                    setTestData(res.data);
                }
            })
        }
        fetchData();
    },[project]);

    return(
        <Box sx={{ textAlign: 'center', p: 3, backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
            <Typography variant="h5" gutterBottom>
              Fuel Dispensor Detailed Test Report
            </Typography>
            <ProjectDetail applicationData={project} />
            {testData!=null?
            <>
            <div>
                {(testData.accuracyTestResult).map((testResult,index)=>
                    <>
                        <Typography variant="h6" gutterBottom>
                            Accuracy Test {index+1}
                        </Typography>
                        <TestTable1 
                        testData={testResult.testData} 
                        applicationData={testResult.applicationData}
                        tableData={testResult.rows} 
                        />
                    </>
                 )}
            </div>
            <div>
                {(testData.flowIntruptionTestResult).map((testResult,index)=>
                <>
                    <Typography variant="h6" gutterBottom>
                        Flow Intruption Test {index+1}
                    </Typography>
                </>
                )}
            </div>
            <div>
                {(testData.minMeasuredQtyTestResult).map((testResult,index)=>
                <>
                    <Typography variant="h6" gutterBottom>
                       Min Measured Qty Test {index+1}
                    </Typography>
                </>
                )}
            </div>
            </>:<></>}
        </Box>
    );
}

export default DetailedTestResult;