import React from 'react';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';

const MyTableRow = ({ row }) => {
  const handleInputChange = (event, key) => {
    const updatedValue = event.target.value;
    row[key] = updatedValue;
  };


  return (
    <React.Fragment>
      {Object.entries(row).map(([key, value]) => (
        <TableCell key={key}>
          <TextField
            value={value}
            onChange={(event) => handleInputChange(event, key)}
          />
        </TableCell>
      ))}
    </React.Fragment>
  );
};

export default MyTableRow;
