import { useLocation, useNavigate } from "react-router-dom";
import { Button, AppBar, Toolbar, Typography, Select, MenuItem, Grid } from "@mui/material";
import React, { useState } from "react";
import ProjectDetail from "../Components/ProjectDetail";

const ProjectDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const project = location.state;
    const [selectedTest, setSelectedTest] = useState(""); // State to hold selected test

    const handleTestChange = (event) => {
        setSelectedTest(event.target.value);
    };

    const handleAddTest = () => {
        if (selectedTest===1) {
            navigate("/accuracy-test", { state: { ...project, selectedTest } });
        }
        if(selectedTest ===2){
            navigate("/flow-intruption-test", { state: { ...project, selectedTest } });
        }
        if(selectedTest ===3){
            navigate("/min-measured-Qty", { state: { ...project, selectedTest } });
        }
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">Project Details</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ padding: "20px" }}>
                <ProjectDetail applicationData={project} />
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Select
                            value={selectedTest}
                            onChange={handleTestChange}
                            style={{ width: "200px" }}
                        >
                            <MenuItem value={1}>Accuracy Test</MenuItem>
                            <MenuItem value={2}>Flow Intruption Test</MenuItem>
                            <MenuItem value={3}>Minimum Measured Quantity</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleAddTest}
                            disabled={!selectedTest} // Disable button if no test is selected
                        >
                            Add Test Result
                        </Button>
                    </Grid>
                    {/* <Grid item>
                        <Button variant="contained" onClick={()=>navigate("/detailed-project-report",{state:project})}>
                            View Tests Result
                        </Button>
                    </Grid> */}
                </Grid>
            </div>
        </>
    );
};

export default ProjectDetails;
