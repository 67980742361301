import React, { useState } from 'react';
import { TextField, Button, Container, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const ProjectForm = () => {
    const navigate=useNavigate();
    const [formData, setFormData] = useState({
        applicationNo: '',
        patternDesignation: '',
        observer: '',
        date: '', // Change to string for TextField
        serialNo: '',
        modelSeries: '',
        modelBrandName: '',
        liquidType: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(process.env.REACT_APP_BACKEND_URL+"/projects", formData).then(res=>{
                if(res.status === 200){
                    navigate("/project-details",{state:res.data})
                } else {
                    alert("Error creating project")
                    setFormData({
                        applicationNo: '',
                        patternDesignation: '',
                        observer: '',
                        date: '', 
                        serialNo:'',
                        modelSeries: '',
                        modelBrandName: '',
                        liquidType: '',
                    });
                }

            })
            // Reset form data after successful submission if needed
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div style={{ background: '#f0f0f0', minHeight: '100vh', padding: '20px' }}>
            <Container maxWidth="sm" style={{ background: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Stack spacing={3}>
                    <h2 style={{ textAlign: 'center' }}>Project Details</h2>
                    <form onSubmit={handleSubmit} style={{ display: 'grid', gap: '20px' }}>
                        <TextField
                            type="text"
                            name="applicationNo"
                            variant='outlined'
                            color='secondary'
                            label="Application Number"
                            onChange={handleChange}
                            value={formData.applicationNo}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            name="patternDesignation"
                            variant='outlined'
                            color='secondary'
                            label="Pattern Designation"
                            onChange={handleChange}
                            value={formData.patternDesignation}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            name="observer"
                            variant='outlined'
                            color='secondary'
                            label="Observer"
                            onChange={handleChange}
                            value={formData.observer}
                            fullWidth
                            required
                        />
                        <TextField
                            type="date"
                            name="date"
                            variant='outlined'
                            color='secondary'
                            onChange={handleChange}
                            value={formData.date}
                            fullWidth
                            required
                        />
                         <TextField
                            type="text"
                            name="serialNo"
                            variant='outlined'
                            color='secondary'
                            label="Serial No."
                            onChange={handleChange}
                            value={formData.serialNo}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            name="modelSeries"
                            variant='outlined'
                            color='secondary'
                            label="Model Series"
                            onChange={handleChange}
                            value={formData.modelSeries}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            name="modelBrandName"
                            variant='outlined'
                            color='secondary'
                            label="Model Brand Name"
                            onChange={handleChange}
                            value={formData.modelBrandName}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            name="liquidType"
                            variant='outlined'
                            color='secondary'
                            label="Liquid Type"
                            onChange={handleChange}
                            value={formData.liquidType}
                            fullWidth
                            required
                        />
                        <Button variant="contained" color="secondary" type="submit" style={{ justifySelf: 'center' }}>Create Project</Button>
                    </form>
                </Stack>
            </Container>
        </div>
    );
};

export default ProjectForm;
