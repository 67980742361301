import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const BasicCard = ({ project }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ borderRadius: 8, backgroundColor: '#f8f8f8', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', maxWidth: 300 }}>
      <CardContent sx={{ paddingBottom: 8 }}>
        <table>
          <tbody>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Application No:</Typography></td>
              <td><Typography variant="body2">{project.applicationNo}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Pattern Designation:</Typography></td>
              <td><Typography variant="body2">{project.patternDesignation}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Observer:</Typography></td>
              <td><Typography variant="body2">{project.observer}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Date:</Typography></td>
              <td><Typography variant="body2">{project.date}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Serial No:</Typography></td>
              <td><Typography variant="body2">{project.serialNo}</Typography></td>
            </tr>  
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Model Series:</Typography></td>
              <td><Typography variant="body2">{project.modelSeries}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Model Brand:</Typography></td>
              <td><Typography variant="body2">{project.modelBrandName}</Typography></td>
            </tr>
            <tr>
              <td><Typography variant="subtitle2" color="textSecondary">Liquid:</Typography></td>
              <td><Typography variant="body2">{project.liquidType}</Typography></td>
            </tr>
          </tbody>
        </table>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" onClick={() => navigate('/project-details', { state: project })} color="primary">
          Explore
        </Button>
      </CardActions>
    </Card>
  );
};

export default BasicCard;
