import React from "react";
import ProjectForm from "./Pages/CreateProject";
import HomePage from "./Pages/HomePage";
import { Routes, Route } from "react-router-dom";
import ExistingProjects from "./Pages/ExistingProjects";
import ProjectDetails from "./Pages/ProjectDetails";
import AccuracyTest from "./Pages/AccuracyTest";
import FlowIntruptionTest from "./Pages/FlowIntruptionTest";
import MinMeasuredQty from "./Pages/MinMeasuredQty";
import DetailedTestResult from "./Pages/DetailedTestResult";
import DownloadPdf from "./Utils/DownloadPdf";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/create-project" element={<ProjectForm />} />
        <Route path="/projects" element={<ExistingProjects />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/accuracy-test" element={<AccuracyTest />} />
        <Route path="/flow-intruption-test" element={<FlowIntruptionTest/>} />
        <Route path="/min-measured-Qty" element={<MinMeasuredQty />} />
        <Route path="/detailed-project-report" element={<DetailedTestResult />} />
        <Route path="/download-pdf/:id1/:id2" element={<DownloadPdf />} />
      </Routes>
    </div>
  );
}

export default App;
