import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MyTableRow from './TableTextField';
import generatePDF from '../Utils/GeneratePdf';


export default function TestTable1({ testData, applicationData, tableData }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
  ]);

  useEffect(() => {
    console.log(typeof (1 + testData.beta * (testData.finalTemp - testData.initialTemp)));
    const updatedRows = rows.map(row => ({
      ...row,
      Pi: row.Pu * row.Vi,
      Pc: row.Pu * row.Vi,
      Ev: ((row.Vn * (1 + testData.beta * (testData.finalTemp - testData.initialTemp)) - row.Vi) * 100 / row.Vi).toFixed(2),
      Ep: (row.Pu * row.Vi - row.Pu * row.Vi) * 100 / (row.Pu * row.Vi),
      MSPD:testData.mspd,
      T: testData.finalTemp,
      Vnc: ((1 + testData.beta * (testData.finalTemp - testData.initialTemp))*row.Vn).toFixed(2),
    }));
    console.log(updatedRows)

    setRows(updatedRows);
  }, [rows, testData]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSubmitTest = async () => {
    const combinedData = {
      rows: rows,
      testData: testData,
      applicationData: applicationData
    };
    console.log(combinedData);

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => await axios(
            {
              method:"POST",
              url:process.env.REACT_APP_BACKEND_URL+"/accuracy-test",
              data:combinedData
            }).then(res=>{
              confirmAlert({
              title: 'Do You Want to Print Report',
              buttons: [
                {
                  label: 'Yes',
                  onClick:()=>{
                    generatePDF(applicationData,testData,rows);
                    navigate("/project-details",{state:applicationData})
                  }
                },
                {
                  label: 'No',
                  onClick:()=>navigate("/project-details",{state:applicationData})
                }
              ]
            })
          })
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  };

  return (
    <div>
       
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Q (L/min)</TableCell>
              <TableCell align="center">Pu (Rs/L)</TableCell>
              <TableCell align="center">Vi(L)</TableCell>
              <TableCell align="center">Pi(Rs)</TableCell>
              <TableCell align="center">Vn(L)</TableCell>
              <TableCell align="center">T(°C)</TableCell>
              <TableCell align="center">Vnc(L)</TableCell>
              <TableCell align="center">Pc(Rs)</TableCell>
              <TableCell align="center">Ev(%)</TableCell>
              <TableCell align="center">Mpe(%)</TableCell>
              <TableCell align="center">Ep(Rs)</TableCell>
              <TableCell align="center">MSPD(Rs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <MyTableRow row={row} index={index} handleInputChange={handleInputChange} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSubmitTest} sx={{ mr: 2 }}>
          Submit Test
        </Button>
      </Box>
    </div>
  );
}
