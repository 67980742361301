import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode';
import moment from 'moment';

export default function FlowIntruptionTable({ testData, applicationData }) {
  const navigate = useNavigate();
  const [modelTestedAt, setModelTestedAt] = useState('');
  const [rows, setRows] = useState([
    {
      FlowRate: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      FlowRate: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      FlowRate: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
  ]);

  useEffect(() => {
    console.log(typeof (1 + testData.beta * (testData.finalTemp - testData.initialTemp)));
    const updatedRows = rows.map(row => ({
      ...row,
      Pi: row.Pu * row.Vi,
      Pc: row.Pu * row.Vi,
      Ev: ((row.Vn * (1 + testData.beta * (testData.finalTemp - testData.initialTemp)) - row.Vi) * 100 / row.Vi).toFixed(2),
      Ep: (row.Pu * row.Vi - row.Pu * row.Vi) * 100 / (row.Pu * row.Vi),
      MSPD:testData.mspd,
      T: testData.finalTemp,
      Vnc: ((1 + testData.beta * (testData.finalTemp - testData.initialTemp))*row.Vn).toFixed(2),
    }));

    setRows(updatedRows);
  }, [rows, testData]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSubmitTest = async () => {

    const hasZeroValues = rows.some(row => row.FlowRate === 0 || row.Vi === 0 || row.Vn === 0 || row.Pi === 0);
    if (hasZeroValues) {
      alert('Please ensure Q, Vi, Vn, and Pi should not zero.');
      return;
    }
    
    document.getElementById("submitbtn").style.background="#3D3B40";
    document.getElementById("submitbtn").disabled=true;
    const combinedData = {
      rows: rows,
      testData: testData,
      applicationData: applicationData,
      modelTestedAt: modelTestedAt,
    };

    confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => await axios({
                method:"POST",
                url:process.env.REACT_APP_BACKEND_URL+"/flow-intruption-test",
                data:combinedData
              }).then(res=>{
                if(res.status === 200){
                    const url=process.env.REACT_APP_API_URL+"/download-pdf/flowintruptiontests/"+res.data._id;
                    generatePDF(url);
                    navigate("/project-details",{state:applicationData})
                    }
                     else {
                    confirmAlert({
                      title: 'Error',
                      message: 'Error submitting form',
                      buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                                console.log("Form submission request cancelled")
                            }
                          }
                      ]
                    });
                  }
              })
          },
          {
            label: 'No',
            onClick: () => {
                console.log("Form submission request cancelled")
            }
          }
        ]
      });
  };

  const generateQR = async (text) => {
    try {
      const imageDataURL = await QRCode.toDataURL(text);
      return imageDataURL;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const generatePDF = async (url) => {
    const qrCodeDataURL = await generateQR(url);
    const doc = new jsPDF('p', 'mm', 'a4');

    const qrCodeWidth = 40;
    const qrCodeHeight = 40; 
    const marginTopRight = 10;
    const topRightX = doc.internal.pageSize.getWidth() - qrCodeWidth - marginTopRight;
    const topRightY = 0; 
    doc.addImage(qrCodeDataURL, 'PNG', topRightX, topRightY, qrCodeWidth, qrCodeHeight); 


    // overall margin
    const margin = {
      left: 15,
      right: 15,
      top: 40,
      bottom: 20,
    };

    const spacing = 5;
    const sections = 2;
    const printWidht = doc.internal.pageSize.width - (margin.left + margin.right);
    const sectionWidth = (printWidht - ((sections - 1) * spacing)) / sections;
    let startY = margin.top;
    
    doc.setFontSize(18);
    doc.text('Flow Intruption Test Report', 15, 15);
    doc.setFontSize(12);
    doc.text('(As per OIML guidelines)', 15, 20);

    // Section 1: Application Data
    doc.setFontSize(14);
    doc.text('Application Data:', 15, 35);
    doc.autoTable({
      tableWidth: sectionWidth,
      margin: {
        left: margin.left,
        top: margin.top,
        bottom: margin.bottom,
      },
      startY,
      currentSection:0,
        head: [['Field', 'Value']],
        body: [
          ["Application No",applicationData.applicationNo],
          ["PatternDesignation",applicationData.patternDesignation],
          ["Date",moment(applicationData.date).format('DD-MM-YYYY')],
          ["Observer",applicationData.observer],
          ["Serial No",applicationData.serialNo],
          ["Model Series",applicationData.modelSeries],
          ["Model Brand Series",applicationData.modelBrandName],
          ["Liquid",applicationData.liquidType]
        ]
    });

    // Section 2: Test Data
    const testDataFormatted = {
        'Initial/Final Temperature': `${testData.initialTemp} °C / ${testData.finalTemp} °C`,
        'Beta': testData.beta,
        'MMQ': testData.mmq,
        'Max/Min Rate': `${testData.maxRate} / ${testData.minRate} L/min`,
        'Unit Price': `Rs. ${testData.unitprice}`,
        'Beta Temp': testData.betatemp,
        'Emin': testData.emin,
        'MSPD': `Rs. ${testData.mspd}`
    };

    const testDataY = doc.autoTable.previous.finalY + 15;
    doc.text('Test Data:', margin.left + sectionWidth + spacing, 35 );
    doc.autoTable({
      tableWidth: sectionWidth,
      margin: {
        left: margin.left + ((sectionWidth + spacing)),
        top: margin.top,
        bottom: margin.bottom,
      },
      startY,
      currentSection:1,
        head: [['Field', 'Value']],
        body: Object.entries(testDataFormatted)
    });

    // Add table
    doc.text('Result Data:', 15,  doc.autoTable.previous.finalY+15)
    const tableData =  rows.map((row, index) => [index + 1, ...Object.values(row)]);
    const tableY = Math.max(doc.autoTable.previous.finalY + 10, testDataY + 5);
    doc.autoTable({
        startY: tableY,
        head: [['#', 'Q (L/min)', 'Pu (Rs/L)', 'Vi(L)', 'Pi(Rs)', 'Vn(L)', 'T(°C)', 'Vnc(L)', 'Pc(Rs)', 'Ev(%)', 'Mpe(%)', 'Ep(Rs)', 'MSPD(Rs)']],
        body: tableData,
        theme:'grid'
    });

    doc.setFontSize(12);
    const checkboxesY = Math.max(doc.autoTable.previous.finalY + 10, tableY + 5);
    doc.text("Model Tested At: "+modelTestedAt, 15, checkboxesY);

    // Save the PDF
    doc.save('/flow_intruption_test_report.pdf');
};



  return (
    <div>
       
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Flow Rate</TableCell>
              <TableCell align="center">Pu (Rs/L)</TableCell>
              <TableCell align="center">Vi(L)</TableCell>
              <TableCell align="center">Pi(Rs)</TableCell>
              <TableCell align="center">Vn(L)</TableCell>
              <TableCell align="center">T(°C)</TableCell>
              <TableCell align="center">Vnc(L)</TableCell>
              <TableCell align="center">Pc(Rs)</TableCell>
              <TableCell align="center">Ev(%)</TableCell>
              <TableCell align="center">Mpe(%)</TableCell>
              <TableCell align="center">Ep(Rs)</TableCell>
              <TableCell align="center">MSPD(Rs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index+1}</TableCell>
                <TableCell align="center">
                  <TextField
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.FlowRate}
                    onChange={(e) => handleInputChange(index, 'FlowRate', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Pu}
                    onChange={(e) => handleInputChange(index, 'Pu', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }
                  }}
                    value={row.Vi}
                    onChange={(e) => handleInputChange(index, 'Vi', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={row.Pi}
                  disabled
                /></TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Vn}
                    onChange={(e) => handleInputChange(index, 'Vn', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.T}
                    onChange={(e) => handleInputChange(index, 'T', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center"><TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                value={row.Vnc}
                disabled
                />

                </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={row.Pc}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={isNaN(row.Ev) ? 0 : row.Ev}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Mpe}
                    onChange={(e) => handleInputChange(index, 'Mpe', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={isNaN(row.Ep) ? 0 : row.Ep}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  
                  value={testData.mspd}
                  disabled /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TextField
        size="small"
        label="Model Tested At"
        fullWidth
        value={modelTestedAt}
        onChange={e => setModelTestedAt(e.target.value)}
        variant="outlined"
        color="secondary"
        sx={{ mb: 2 }}
        />
      <Box sx={{ mt: 3 }}>
        <Button id="submitbtn" variant="contained" color="primary" onClick={handleSubmitTest} sx={{ mr: 2 }}>
          Submit Test
        </Button>
      </Box>
    </div>
  );
}
