import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import BasicCard from "../Components/CardComponent";
import axios from "axios";

const ExistingProjects = () => {
  const [projects, setProjects] = useState([]);
  
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_BACKEND_URL+"/projects"
    }).then(res => {
      setProjects(res.data);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      {projects.length === 0 ? (
        <Grid item xs={12}>
          No Projects to Display
        </Grid>
      ) : (
        projects.map(project => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={project._id}>
            <BasicCard project={project} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default ExistingProjects;
