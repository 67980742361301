import QRCode from 'qrcode';

const generateQR = async (text) => {
    try {
      const imageDataURL = await QRCode.toDataURL(text);
      return imageDataURL;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

export default generateQR;