import { Grid, Table, TableBody, TableCell, 
    TableContainer, TableRow, Paper } from '@mui/material';

const ProjectDetail=({applicationData})=>{
    return (
        <Grid container spacing={2} justifyContent="center" sx={{ paddingBottom: 5 }}>
        <Grid item xs={12} md={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><strong>Application No:</strong></TableCell>
                  <TableCell>{applicationData.applicationNo}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell><strong>Date:</strong></TableCell>
                  <TableCell>{applicationData.date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
              <TableRow>
                  <TableCell><strong>Pattern Designation:</strong></TableCell>
                  <TableCell>{applicationData.patternDesignation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Observer:</strong></TableCell>
                  <TableCell>{applicationData.observer}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
              <TableRow>
                  <TableCell><strong>Model Series:</strong></TableCell>
                  <TableCell>{applicationData.modelSeries}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Model Brand Name:</strong></TableCell>
                  <TableCell>{applicationData.modelBrandName}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );

}
export default ProjectDetail;