import React from "react";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

function HomePage() {
    const navigate=useNavigate();

  return (
    <div>
      <Stack spacing={2}>
      <Button variant="outlined" onClick={()=>navigate('/create-project')}>Create New Project</Button>
      <Button variant="outlined" onClick={()=>navigate('/projects')}>View Existing Project</Button>
      </Stack>
    </div>
  );
}

export default HomePage;
