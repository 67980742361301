import axios from 'axios';

async function handleWebRequest(method, url, params, data) {
    const res = await axios({
        method: method,
        url: url,
        params: params,
        data: data
    });

    if (res.status === 200) {
        return res;
    }
    else {
        return null;
    }
}

export default handleWebRequest;
