import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import generatePDF from '../Utils/GeneratePdf';
import handleWebRequest from '../Utils/handleWebRequest';
import moment from 'moment';
import DownloadPdf from '../Utils/DownloadPdf';


export default function AccuracyTestTable({ testData, applicationData }) {
  const navigate = useNavigate();
  const [modelTestedAt, setModelTestedAt] = useState('');
  const [rows, setRows] = useState([
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
    {
      Q: 0, Pu: 0, Vi: 0,
      Pi: 0, Vn: 0,
      T: testData.finalTemp,
      Vnc: 0,
      Pc: 0, Ev: 0,
      Mpe: 0, Ep: 0,
      MSPD: testData.mspd
    },
  ]);

  useEffect(() => {
    const updatedRows = rows.map(row => ({
      ...row,
      Pi: row.Pu * row.Vi,
      Pc: row.Pu * row.Vi,
      Ev: ((row.Vn * (1 + testData.beta * (testData.finalTemp - testData.initialTemp)) - row.Vi) * 100 / row.Vi).toFixed(2),
      Ep: (row.Pu * row.Vi - row.Pu * row.Vi) * 100 / (row.Pu * row.Vi),
      MSPD:testData.mspd,
      T: testData.finalTemp,
      Vnc: ((1 + testData.beta * (testData.finalTemp - testData.initialTemp))*row.Vn).toFixed(2),
    }));
    setRows(updatedRows);
  }, [rows, testData]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSubmitTest = async () => {

    const hasZeroValues = rows.some(row => row.Q === 0 || row.Vi === 0 || row.Vn === 0 || row.Pi === 0);
    if (hasZeroValues) {
      alert('Please ensure Q, Vi, Vn, and Pi should not zero.');
      return;
    }

    const combinedData = {
      rows: rows,
      testData: testData,
      applicationData: applicationData,
      modelTestedAt: modelTestedAt,
    };

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const res=await handleWebRequest("POST",process.env.REACT_APP_BACKEND_URL+"/accuracy-test",'',combinedData)
            if(res.status===200){
              const url=process.env.REACT_APP_API_URL+"/download-pdf/accuracytests/"+res.data._id;
              generatePDF("Accuracy Test Report", applicationData, testData, rows, url,modelTestedAt);
              navigate("/project-details",{state:applicationData})
          }
        }
      },
      {
        label: 'No',
        onClick: () => console.log('No')
      }
      ]
    });
  };

  return (
    <div>   
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Q (L/min)</TableCell>
              <TableCell align="center">Pu (Rs/L)</TableCell>
              <TableCell align="center">Vi(L)</TableCell>
              <TableCell align="center">Pi(Rs)</TableCell>
              <TableCell align="center">Vn(L)</TableCell>
              <TableCell align="center">T(°C)</TableCell>
              <TableCell align="center">Vnc(L)</TableCell>
              <TableCell align="center">Pc(Rs)</TableCell>
              <TableCell align="center">Ev(%)</TableCell>
              <TableCell align="center">Mpe(%)</TableCell>
              <TableCell align="center">Ep(Rs)</TableCell>
              <TableCell align="center">MSPD(Rs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index+1}</TableCell>
                <TableCell align="center">
                  <TextField
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Q}
                    onChange={(e) => handleInputChange(index, 'Q', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Pu}
                    onChange={(e) => handleInputChange(index, 'Pu', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }
                  }}
                    value={row.Vi}
                    onChange={(e) => handleInputChange(index, 'Vi', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={row.Pi}
                  disabled
                /></TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Vn}
                    onChange={(e) => handleInputChange(index, 'Vn', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.T}
                    onChange={(e) => handleInputChange(index, 'T', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center"><TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                value={row.Vnc}
                disabled
                />

                </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={row.Pc}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={isNaN(row.Ev) ? 0 : row.Ev}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                  <TextField
   
                    size="small"
                    sx={{'& .MuiInputBase-input': {
                      textAlign: "center",
                      fontSize:14
                    }}}
                    value={row.Mpe}
                    onChange={(e) => handleInputChange(index, 'Mpe', e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  value={isNaN(row.Ep) ? 0 : row.Ep}
                  disabled />
                  </TableCell>
                <TableCell align="center">
                <TextField
                  size="small"
                  sx={{'& .MuiInputBase-input': {
                    textAlign: "center",
                    fontSize:14
                    // fontWeight:1000,
                    
                  },
                  '& .Mui-disabled':{
                    '-webkit-text-fill-color':'#000'
                  },
                  backgroundColor: '#f2f2f2',
                  
                }}
                  
                  value={testData.mspd}
                  disabled /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TextField
        size="small"
        label="Model Tested At"
        fullWidth
        value={modelTestedAt}
        onChange={e => setModelTestedAt(e.target.value)}
        variant="outlined"
        color="secondary"
        sx={{ mb: 2 }}
        />
      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSubmitTest} sx={{ mr: 2 }}>
          Submit Test
        </Button>
      </Box>
    </div>
  );
}
