import jsPDF from 'jspdf';
import 'jspdf-autotable';
import generateQR from './GenerateQR';
import moment from 'moment';

const generatePDF = async (title,applicationData,testData,rows,url,modelTestedAt) => {
    const qrCodeDataURL = await generateQR(url);
    const doc = new jsPDF('p', 'mm', 'a4');
    const qrCodeWidth = 40;
    const qrCodeHeight = 40; 
    const marginTopRight = 10;
    const topRightX = doc.internal.pageSize.getWidth() - qrCodeWidth - marginTopRight;
    const topRightY = 0; 
    doc.addImage(qrCodeDataURL, 'PNG', topRightX, topRightY, qrCodeWidth, qrCodeHeight); 

    // overall margin
    const margin = {
      left: 15,
      right: 15,
      top: 40,
      bottom: 20,
    };

    const spacing = 5;
    const sections = 2;
    const printWidht = doc.internal.pageSize.width - (margin.left + margin.right);
    const sectionWidth = (printWidht - ((sections - 1) * spacing)) / sections;
    let startY = margin.top;
    
    doc.setFontSize(18);
    doc.text(title, 15, 15);
    doc.setFontSize(12);
    doc.text('(As per OIML guidelines)', 15, 20);
    // Section 1: Application Data
    doc.setFontSize(14);
    doc.text('Application Data:', 15, 35);
    doc.autoTable({
      tableWidth: sectionWidth,
      margin: {
        left: margin.left,
        top: margin.top,
        bottom: margin.bottom,
      },
      startY,
      currentSection:0,
        head: [['Field', 'Value']],
        body: [
          ["Application No",applicationData.applicationNo],
          ["PatternDesignation",applicationData.patternDesignation],
          ["Date",moment(applicationData.date).format('DD-MM-YYYY')],
          ["Observer",applicationData.observer],
          ["Serial No",applicationData.serialNo],
          ["Model Series",applicationData.modelSeries],
          ["Model Brand Series",applicationData.modelBrandName],
          ["Liquid",applicationData.liquidType]
        ]
    });

    // Section 2: Test Data
    const testDataFormatted = {
        'Initial/Final Temperature': `${testData.initialTemp} °C / ${testData.finalTemp} °C`,
        'Beta': testData.beta,
        'MMQ': testData.mmq,
        'Max/Min Rate': `${testData.maxRate} / ${testData.minRate} L/min`,
        'Unit Price': `Rs. ${testData.unitprice}`,
        'Beta Temp': testData.betatemp,
        'Emin': testData.emin,
        'MSPD': `Rs. ${testData.mspd}`
    };

    const testDataY = doc.autoTable.previous.finalY + 15;
    doc.text('Test Data:', margin.left + sectionWidth + spacing, 35 );
    doc.autoTable({
      tableWidth: sectionWidth,
      margin: {
        left: margin.left + ((sectionWidth + spacing)),
        top: margin.top,
        bottom: margin.bottom,
      },
      startY,
      currentSection:1,
        head: [['Field', 'Value']],
        body: Object.entries(testDataFormatted)
    });

    // Add table
    doc.text('Result Data:', 15,  doc.autoTable.previous.finalY+15)
    const tableData =  rows.map((row, index) => [index + 1, ...Object.values(row)]);
    const tableY = Math.max(doc.autoTable.previous.finalY + 10, testDataY + 5);
    doc.autoTable({
        startY: tableY,
        head: [['#', 'Q (L/min)', 'Pu (Rs/L)', 'Vi(L)', 'Pi(Rs)', 'Vn(L)', 'T(°C)', 'Vnc(L)', 'Pc(Rs)', 'Ev(%)', 'Mpe(%)', 'Ep(Rs)', 'MSPD(Rs)']],
        body: tableData,
        theme:'grid'
    });

    doc.setFontSize(12);
    const checkboxesY = Math.max(doc.autoTable.previous.finalY + 10, tableY + 5);
    doc.text("Model Tested At: "+modelTestedAt, 15, checkboxesY);

    // Save the PDF
    doc.save(title+'.pdf');
};

export default generatePDF;